import React from "react"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"
import { motion } from 'framer-motion'

const WebDevPage = () => (
    <Layout>
        <Seo title="Web developers Sydney & Canberra | Web development Sydney" 
            description="Experienced Sydney web developers. Launch Lab is a highly experienced Australian web development agency. We develop websites, startups and applications."
            pathname="/web-development/"
            serviceType="Web Development"
        />

        <section role="main">
            <div className="container">
                <div className="row flex flex-jc">
                <div className="hero-content sub-hero">
                    <h1
                    className="small-h"
                    ><Link to="/web-development/" className="underline-magic">Web Developers Sydney and Canberra</Link>, Australia</h1>
                    <motion.h2 
                    className="hero-lead"
                    initial="hidden"
                    animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
                    transition={{ ease: "easeOut", duration: 0.5 }}
                    >
                        We're a highly experienced Australian web development agency
                    </motion.h2>
                    <div className="btn-row">
                    <motion.div 
                        initial="hidden"
                        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                        transition={{ ease: "easeOut", duration: 0.5 }}
                    > 
                        <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-purple"
                        >
                        <span className="flex flex-ac down">
                            <span className="visually-hidden">Clicking this button will scroll the screen down to the next text section on this page.</span>
                            <ArrowRight />
                        </span>
                        </button>
                    </motion.div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">100+</span>
                            <p>We've developed more than 100 websites, startups, chatbots and apps.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">360</span>
                            <p>We offer a 360 degree service from web design to web development.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">SYD / CAN</span>
                            <p>Our web development work is done onshore in Sydney and Canberra.</p>
                        </div>
                    </div>
                    <div className="work-jumbo-wrapper mt-4 with-radius">
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/contact/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                                Start your project
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Websites &amp; applications</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                            All of Launch Lab's web development work is completed in Australia by a <strong>senior team of web developers</strong>.
                            </p>
                            <p>
                                We've completed projects for large brands like Xero-owned Waddle, <Link to="/aws-infrastructure/">AWS hosting &amp; infrastructure</Link> work for NSW Government, chatbots for City of Sydney and are trusted by entreprenuers to develop startups that require custom application development using <Link to="/python-developer/">Python</Link> and <Link to="/react-developers-sydney-canberra/">React</Link>.
                            </p>
                            <p>
                                A lot of the work we do is as <Link to="/startup-web-application-development/">startup developers</Link> which includes designing and developing marketing websites and cloud-based web applications.
                            </p>
                            <p className="black-lt">
                                We also develop corporate marketing websites using tools like <Link to="/gatsby-developer/">Gatsby</Link> and Astro and have experience with both <Link to="/headless-cms/">headless cms development</Link> and Django.
                            </p>    
                            <p>Launch Lab also offer no-code (or we prefer to say low-code) websites using Webflow. If you're looking for a <Link to="/webflow-developer/">Webflow developer in Sydney</Link> we'd love to hear about your project.</p>    
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Start your project
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Portfolio work</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/case-study-giraffe-webflow-website/" className="porti-item">
                            <div className="porti-item-img bg-noise giraffe">
                                <StaticImage 
                                    src="../images/giraffe.png"
                                    alt="A collage of the Giraffe website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Giraffe</h3>
                                <p>We designed and developed a new Webflow website for this Sydney startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/feezy-case-study/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/feezy-website.png"
                                alt="Feezy website home page"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Feezy</h3>
                            <p>We designed and developed a 2-sided marketplace web application for this Sydney, Australia, based startup.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill website">Website</div>
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>
                        
                        <Link to="/webflow-website-case-study/" className="porti-item">
                            <div className="porti-item-img bg-noise immu">
                                <StaticImage 
                                    src="../images/immutable-site.png"
                                    alt="Multiple screens from the Immutable website"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Immutable</h4>
                                <p>We developed the Webflow Enterprise website for this Sydney scaleup and continue to offer ongoing Webflow support.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/startup-case-study-fintech/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/deferit-image.png"
                                alt="Image of lady smiling and other design assets from Deferit, a fintech startup in Australia"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h3>Deferit</h3>
                            <p>We developed a web and mobile app for Deferit, a fintech startup in Australia.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill mobileapp">Mobile App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>
                        
                    </motion.div>
                </div>
                
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-purple">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67" itemscope itemtype="https://schema.org/FAQPage">
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Are your web developers based in Sydney and Canberra?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>We're a senior onshore web development team. Our web developers are based in Sydney and Canberra. Our dev work is done onshore in Australia.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you offer ongoing website maintenance and support?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Yes. We won't leave you in the trenches on your own. We're happy to manage AWS servers and website hosting for you as well as implementing upgrades and changes to your website on a regular basis.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you only work with startups?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>No. Developing early stage startups takes up about 70% of time. The rest is filled with developing websites and applications for leading brands, Government, Australian Universities and later stage funded, and acquired, startups.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you offer web design services?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Yes. We offer a <Link to="/web-design/">web design</Link> and product design service. However, if you've already had your website designed we're happy to develop it for you based on supplied design files.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Where do you host your websites?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Our preference is to use Amazon Web Services when custom building websites and applications. Regardless of the product being a static website, a CMS solution or a fully featured web application, the AWS suite of products provides all the tools we need to move quickly with tools we trust.</p>
                                    <p>This only changes if we <a href="/webflow-developer/">develop your website on Webflow</a>. In that case, your website will be hosted on Webflow.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="If you are looking for an experienced web developer in Sydney or Canberra Australia we'd love to discuss your project with you."
        />
    </Layout>
)

export default WebDevPage